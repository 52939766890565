<template>
  <b-modal
    :id="'modalAddItemNewComponent'"
    centered
    size="lg"
    title="Add new"
    @show="showSubmitButton"
  >
    <form class="form" v-on:submit.prevent="createItem('modalAddItem')">
      <div>
        <div
          v-for="item in dataForForm.fields"
          :key="item.field"
          class="form-group row"
        >
          <label class="col-xl-3 col-lg-2 col-form-label">{{item.label}}</label>
          <div class="col-lg-10 col-xl-9">
            <input
              class="form-control form-control-lg form-control-solid"
              type="text"
              ref="firstName"
              v-model="form[item.field]"
            />
          </div>
        </div>
      </div>
    </form>
    <template #modal-footer="{ cancel }">
      <b-button class="mr-auto" size="sm" @click="cancel()">Cancel</b-button>
      <span :class="spinner"></span>
      <b-button
        v-if="submitButton"
        size="sm"
        variant="primary"
        @click="createItem('modalAddItem')"
        >Continue</b-button
      >
    </template>
  </b-modal>
</template>
<script>
import axios from 'axios'
   export default {
       props: ['dataForForm'],
	  data(){
          return {
             submitButton: true,
             spinner: '',
             form: {}
          }
      },


    methods: {
		createItem(modalId){
			this.spinner = 'spinner spinner-sm spinner-success spinner-right'
			 this.submitButton = null
			  axios.post('https://keetels-api.eliteswitch.com/api/component', this.form)
			  .then((response) => {
			  console.log(response)
			   this.$bvModal.hide(modalId)
			   this.form = {}

			 this.$parent.refreshTable()

				this.spinner = ''
			  }, (error) => {
				console.log(error)
				this.submitButton = true
				this.spinner = ''
			  })

			// this.$bvModal.hide(modalId)
		},
		showSubmitButton(){
			this.submitButton = true
		}
	}


   }
</script>
